/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useAuth0Context } from "./AuthContext";
import jwt_decode from "jwt-decode";
import getMGUser from "libs/getMGUser";
import { sendMessage } from "libs/sendMessage";
import localRepository from "../../localRepository";
import { sendMessageToNative } from "../nativeAppMessaging/js/actions";
import { WEB_AUTH_STATUS_CHANGE, WEB_LOGOUT } from "../user/js/actions";
import { useLoggerContext } from "contexts/logger/logger.context";
import qs from "query-string";

export const useRefreshToken = (isOnNativeApp, nativeCredentials) => {
  const { authState, renewSession, setSession, logout } = useAuth0();

  // External users authenticated through SSO integration will pass these tokens from params.
  const { accessToken: externalAccessToken, idToken: externalIdToken } =
    qs.parse(window.location.search);

  const accessToken = externalAccessToken
    ? externalAccessToken
    : authState.accessToken;

  const idToken = externalIdToken ? externalIdToken : authState.idToken;

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const metadata = accessToken ? jwt_decode(accessToken) : {};
  const user = getMGUser(metadata);

  useEffect(() => {
    let timerForWebRefresh = null;

    const TWO_MINUTES = 2 * 60 * 1000;
    let timeToExpire = 0;
    if (authState.expiresAt > 0) {
      timeToExpire = authState.expiresAt - new Date().getTime();
      timeToExpire =
        timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
    }

    if (isOnNativeApp) {
      if (
        nativeCredentials &&
        nativeCredentials.accessToken !== authState.accessToken
      ) {
        setSession({
          expiresIn: nativeCredentials.expiresIn,
          accessToken: nativeCredentials.accessToken,
          idToken: nativeCredentials.idToken,
        });
        localRepository.accessToken.set(nativeCredentials.accessToken);
      }
    } else if (user?.isExternal) {
      const expiresIn = metadata.exp - Date.now() / 1000;

      if (expiresIn < 0) return logout();

      localRepository.accessToken.set(accessToken);
      setSession({
        expiresIn,
        accessToken,
        idToken,
      });
    } else {
      timerForWebRefresh = setTimeout(() => {
        if (isLoggedIn) {
          renewSession();
        }
      }, timeToExpire);
    }

    return () => clearTimeout(timerForWebRefresh);
  }, [authState.accessToken, renewSession, isOnNativeApp, nativeCredentials]);
};

export const useAuth0 = () => {
  const { auth0, authState, updateAuthState } = useAuth0Context();
  const { logger } = useLoggerContext();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const { expiresAt } = authState;
    setIsAuthenticated(new Date().getTime() < expiresAt);
  }, [authState]);

  const loginSocial = useCallback(
    (provider) => {
      logger.info(
        `[${useAuth0.name}] [loginSocial] - Authorizing against Auth0...`,
        { provider }
      );
      auth0.authorize({
        connection: provider,
      });
      logger.info(
        `[${useAuth0.name}] [auth0.authorize}] - Authorization complete...`,
        { provider }
      );
    },
    [auth0]
  );

  const loginPasswordlessEmail = useCallback(
    (email) => {
      return new Promise((resolve, reject) => {
        const payload = {
          connection: "email",
          email,
          send: "code",
        };
        logger.info(
          `[${useAuth0.name}] [loginPasswordlessEmail] - Starting passwordless login...`,
          {
            payload,
          }
        );

        auth0.passwordlessStart(payload, (error) => {
          logger.info(
            `[${useAuth0.name}] [${auth0.passwordlessStart.name}] - PasswordlessStart callback inited...`,
            { payload }
          );
          if (error) {
            logger.error(
              `[${useAuth0.name}] [${auth0.passwordlessStart.name}] - PasswordlessStart Failed!`,
              { error }
            );
            reject(error);
          } else {
            logger.info(
              `[${useAuth0.name}] [${auth0.passwordlessStart.name}] - PasswordlessStart successfull!`,
              { payload }
            );
            resolve();
          }
        });
      });
    },
    [auth0]
  );

  const confirmPasswordlessEmail = useCallback(
    (email, verificationCode) => {
      return new Promise((resolve, reject) => {
        const payload = {
          connection: "email",
          email: email,
          verificationCode: verificationCode,
        };

        logger.info(
          `[${useAuth0.name}] [confirmPasswordlessEmail] - PasswordlessLogin inited...`,
          { payload }
        );

        auth0.passwordlessLogin(payload, (error) => {
          if (error) {
            logger.error(
              `[${useAuth0.name}] [${auth0.passwordlessLogin.name}] - PasswordlessLogin Failed...`,
              { error }
            );
            reject(error);
          } else {
            logger.info(
              `[${useAuth0.name}] [${auth0.passwordlessLogin.name}] - PasswordlessLogin successfull!`,
              { payload }
            );
            resolve();
          }
        });
      });
    },
    [auth0]
  );

  const logout = useCallback(
    (returnUrl = "") => {
      logger.info(`[${useAuth0.name}] [logout] - User logout inited...`, {
        returnUrl,
      });
      localRepository.clear();
      localStorage.setItem("isLoggedIn", "false");

      logger.info(`[${useAuth0.name}] [logout] - Local repository cleared...`, {
        returnUrl,
      });

      const updateAuthStatePayload = {
        accessToken: null,
        idToken: null,
        expiresAt: 0,
        user: null,
      };

      updateAuthState(updateAuthStatePayload);

      logger.info(`[${useAuth0.name}] [logout] - Auth state updated...`, {
        updateAuthStatePayload,
      });

      const isOnNativeApp = localRepository.isOnNativeApp.get();

      if (isOnNativeApp) {
        logger.info(
          `[${useAuth0.name}] [logout] - User is on native app. Sending message to Native..`,
          { isOnNativeApp }
        );
        sendMessageToNative({
          type: WEB_LOGOUT,
        });
        logger.info(`[${useAuth0.name}] [logout] - Message sent to Native..`, {
          type: WEB_LOGOUT,
          payload: {},
        });

        const nativeMessagePayload = {
          credentials: "",
          version: localRepository.version.get(),
          logIn: false,
        };
        sendMessageToNative({
          type: WEB_AUTH_STATUS_CHANGE, // TODO: queda solo por compatibilidad con nativo, quitar cuando todos se hayan actualizado
          payload: nativeMessagePayload,
        });
        logger.info(`[${useAuth0.name}] [logout] - Message sent to Native..`, {
          type: WEB_AUTH_STATUS_CHANGE,
          payload: nativeMessagePayload,
        });

        logger.info(`[${useAuth0.name}] [logout] - Reloading window..`, {});
        window.location.reload(false);
      } else {
        logger.info(
          `[${useAuth0.name}] [logout] - User is not in native App. Logging out...`,
          {
            returnTo: window.location.origin + returnUrl,
          }
        );
        auth0.logout({
          returnTo: window.location.origin + returnUrl,
        });
      }
    },
    [auth0, updateAuthState]
  );

  const setSession = useCallback(
    (authResult) => {
      logger.info(`[${useAuth0.name}] [setSession] - Setting user session...`, {
        authResult,
      });

      const auth0TokenData = jwt_decode(authResult.accessToken);
      logger.info(`[${useAuth0.name}] [setSession] - JWT Token decoded...`, {
        auth0TokenData,
      });

      const user = getMGUser(auth0TokenData);
      logger.info(`[${useAuth0.name}] [setSession] - Getting MG User data...`, {
        MgUserData: user,
      });

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("isLoggedIn", "true");
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      localStorage.setItem("expiresAt", expiresAt.toString());
      logger.info(
        `[${useAuth0.name}] [setSession] - Local storage updated...`,
        {
          user: JSON.stringify(user),
          isLoggedIn: true,
          expiresAt,
        }
      );

      const authStatePayload = {
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt,
        user,
      };
      updateAuthState(authStatePayload);
      logger.info(`[${useAuth0.name}] [setSession] - Auth State updated...`, {
        authStatePayload,
      });
    },
    [updateAuthState]
  );

  const renewSession = useCallback(() => {
    logger.info(
      `[${useAuth0.name}] [renewSession] - Session renewal inited...`,
      {}
    );
    auth0.checkSession({}, (error, authResult) => {
      logger.info(
        `[${useAuth0.name}] [renewSession] - CheckSession callback called...`,
        { function: auth0.checkSession.name, authResult, error }
      );

      if (authResult && authResult.accessToken && authResult.idToken) {
        logger.info(
          `[${useAuth0.name}] [renewSession] - Result check successfull! Setting session...`,
          { function: setSession.name, payload: authResult }
        );
        setSession(authResult);
        logger.info(`[${useAuth0.name}] [renewSession] - Session set.`, {
          function: setSession.name,
          payload: authResult,
        });
      } else if (error) {
        logger.error(
          `[${useAuth0.name}] [renewSession] - Could not get a new token.`,
          { function: auth0.checkSession.name, error }
        );
        console.error(
          `Could not get a new token (${error.error}: ${error.error_description}).`
        );
        sendMessage("Hubo un problema, volvé a iniciar sesión.", "warning");

        logger.info(`[${useAuth0.name}] [renewSession] - Logging out...`, {
          function: auth0.checkSession.name,
        });
        logout();
      }
    });
  }, []);

  const handleAuthentication = useCallback(() => {
    logger.info(
      `[${useAuth0.name}] [handleAuthentication] - Handling user authentication...`,
      {}
    );
    auth0.parseHash((error, authResult) => {
      logger.info(
        `[${useAuth0.name}] [handleAuthentication] - ParseHash callback inited...`,
        { function: auth0.parseHash.name, authResult, error }
      );
      if (authResult && authResult.accessToken && authResult.idToken) {
        logger.info(
          `[${useAuth0.name}] [handleAuthentication] - AuthResult check successfull! Setting session...`,
          { function: setSession.name, payload: authResult }
        );
        setSession(authResult);
        logger.info(
          `[${useAuth0.name}] [handleAuthentication] - Session set.`,
          { function: setSession.name, payload: authResult }
        );
      } else if (error) {
        logger.error(
          `[${useAuth0.name}] [handleAuthentication] - Could not handle authentication!`,
          { function: auth0.parseHash.name, error }
        );
        console.error(
          `Error: ${error.error}. Check the console for further details.`
        );
        // sendMessage(
        //   "Hubo un problema con el inicio de sesión, volvé a intentarlo en unos instantes.",
        //   "error"
        // ); // este mensaje no se dispara porque auth0 hace un redirect cuando ejecutamos un logout.

        logger.info(
          `[${useAuth0.name}] [handleAuthentication] - Logging out...`,
          { function: auth0.parseHash.name }
        );
        logout();
      }
    });
  }, []);

  return {
    authState,
    loginSocial,
    loginPasswordlessEmail,
    confirmPasswordlessEmail,
    logout,
    handleAuthentication,
    isAuthenticated,
    renewSession,
    setSession, // NO USAR, es solo para lo de native
  };
};
