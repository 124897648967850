import { createActions, handleActions } from "redux-actions";

const {
  business: {
    modeloVehiculo: {
      fetchModelosVehiculosInited,
      fetchModelosVehiculosCompleted,
      fetchModelosVehiculosFailed,
    },
  },
} = createActions({
  business: {
    modeloVehiculo: {
      fetchModelosVehiculosInited: (props) => ({ props }),
      fetchModelosVehiculosCompleted: (response) => ({ response }),
      fetchModelosVehiculosFailed: (error) => ({ error }),
    },
  },
});

const fetchModelosVehiculos = (marca, anio, preciosModifier) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchModelosVehiculosInited({ marca, anio, preciosModifier }));
      const response = await services.api.getVehiclesVersiones(
        marca,
        anio,
        preciosModifier
      );
      dispatch(fetchModelosVehiculosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchModelosVehiculosFailed(error));
    }
  };
};

const initialState = {
  modelos: [],
  isLoading: false,
};
const modeloVehiculoSelectorReducer = handleActions(
  {
    [fetchModelosVehiculosInited]: () => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
    [fetchModelosVehiculosCompleted]: (state, action) => {
      const modelos = action.payload.response.items.map((x) => ({
        value: x.codia,
        label: x.version,
      }));
      return {
        ...initialState,
        modelos: modelos,
        isLoading: false,
      };
    },
    [fetchModelosVehiculosFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default modeloVehiculoSelectorReducer;
export {
  fetchModelosVehiculos,
  fetchModelosVehiculosInited,
  fetchModelosVehiculosCompleted,
  fetchModelosVehiculosFailed,
};
