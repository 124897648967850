let createPostSegurosCotizar =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Client-Id": "MGApp",
    };
    let request = buildRequestWithAuth("POST", data, headers);

    return fetch(
      `${environment.segurosUrl}/riesgos/automotores/multicotizaciones`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createGetSegurosCotizaciones =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (cotizacionId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.segurosUrl}/riesgos/automotores/multicotizaciones/${cotizacionId}`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.status === 204 ? null : response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createPostSegurosQuieroQueMeContacten =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (id, data) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Client-Id": "MGApp",
    };
    let request = buildRequestWithAuth("POST", data, headers);

    return fetch(
      `${environment.segurosUrl}/riesgos/automotores/multicotizaciones/${id}/solicitudPoliza`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Network or server error:", error.message || error);
        throw error;
      });
  };

let createPostSegurosQuieroEstaPoliza =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(`${environment.segurosUrl}/solicitudespolizas`, request)
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

export {
  createPostSegurosCotizar,
  createGetSegurosCotizaciones,
  createPostSegurosQuieroQueMeContacten,
  createPostSegurosQuieroEstaPoliza,
};
