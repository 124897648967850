import React, { Suspense, useEffect, useMemo } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import MessageViewer from "../notifications/MessageViewer";
import RedirectToHttps from "../shared/redirectToHttps";
import localRepository from "../../localRepository";
import NativeAppMessagingContainer from "../nativeAppMessaging";
import makeStyles from "@material-ui/core/styles/makeStyles";

// new material
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import mgTheme from "assets/mgTheme";
import { useAuth0, useRefreshToken } from "../Auth0/useAuth";
import { logInUserAnalytics, logOutUserAnalytics } from "libs/amplitudeHelper";
import LoadingView from "components/styled/loadingView/LoadingView";
import { AsyncRecoverPassword } from "./LazyComponents";
import Signin from "componentes/auth/Signin";
import { Callback } from "componentes/Auth0/Callback";
import LoginFormContainer from "componentes/user/LoginContainer";
import useHotjar from "components/app/useHotjar";
import Routes from "components/app/Routes";
import AuthenticationRoutes from "components/app/authentication/Routes";
import { useFeatureFlags } from "../shared/featureFlags";
import environment from "environment";
import useLocationAnalytics from "components/app/useLocationAnalytics";
import Headers from "./Headers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { basicLogger } from "launchdarkly-js-client-sdk";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BottomHeightProvider } from "components/contexts/bottomActions/bottomHeight.provider";
import { FiltroEstadoProvider } from "components/contexts/filtroEstado/filtroEstado.provider";
import { LogsProvider } from "contexts/logger/logger.provider";
import { External } from "componentes/auth/External";

const PublicViews = () => {
  return (
    <>
      <Switch>
        <Route exact path="/signin" component={Signin} />
        <Route path="/callback" component={Callback} />
        <Route path="/validating/external" component={External} />
        <Route exact path="/loading" component={LoadingView} />
        <Route exact path="/login/old" component={LoginFormContainer} />
        <Route
          exact
          path="/login/password/:token"
          component={AsyncRecoverPassword}
        />
        <Redirect to="/signin" /> {/*fallback to login*/}
      </Switch>
    </>
  );
};

const AuthenticatedViews = () => {
  useLocationAnalytics();
  return <Routes />;
};

const useAppStyles = makeStyles({
  nonScrollableBodyDefault: {
    backgroundColor: "#f9f7fc",
    position: "fixed",
    overflow: "hidden",
    width: "100%",
  },
});

const App = ({ isOnNativeApp, nativeCredentials }) => {
  const classes = useAppStyles();
  const { isAuthenticated, authState } = useAuth0();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  useRefreshToken(isOnNativeApp, nativeCredentials);

  useEffect(() => {
    if (isLoggedIn && authState.user) {
      logInUserAnalytics(
        authState.user.email,
        authState.user.preferred_username,
        authState.user.sub,
        authState.user.euroUserId,
        localRepository.version.get()
      );
    } else {
      logOutUserAnalytics();
    }
  }, [authState.user, isLoggedIn]);

  useEffect(() => {
    document.body.className = classes.nonScrollableBodyDefault;
  }, [classes.nonScrollableBodyDefault]);

  useFeatureFlags();
  const ldClient = useLDClient();

  useHotjar();

  const supportsHistory = "pushState" in window.history;
  const queryClient = useMemo(
    () => new QueryClient({ defaultOptions: { queries: { retry: 2 } } }),
    []
  );

  return (
    <BrowserRouter keyLength={12} forceRefresh={!supportsHistory}>
      <Route
        render={({ location }) => {
          return (
            <>
              <NativeAppMessagingContainer />
              <CssBaseline />
              <LogsProvider>
                <ThemeProvider theme={mgTheme}>
                  <QueryClientProvider client={queryClient}>
                    <BottomHeightProvider>
                      <FiltroEstadoProvider>
                        <ReactQueryDevtools />
                        <RedirectToHttps>
                          <Suspense fallback={<LoadingView />}>
                            {ldClient && (
                              <>
                                <Headers />
                                <MessageViewer />
                                {isAuthenticated &&
                                  authState.user &&
                                  authState.user.isAuthorized && (
                                    <AuthenticatedViews
                                      location={location}
                                      isOnNativeApp={isOnNativeApp}
                                    />
                                  )}
                                {isAuthenticated &&
                                  !(
                                    authState.user &&
                                    authState.user.isAuthorized
                                  ) && <AuthenticationRoutes />}
                                {!isAuthenticated && !isLoggedIn && (
                                  <PublicViews />
                                )}
                              </>
                            )}
                            {(!ldClient ||
                              (!isAuthenticated && isLoggedIn)) && (
                              <LoadingView />
                            )}
                          </Suspense>
                        </RedirectToHttps>
                      </FiltroEstadoProvider>
                    </BottomHeightProvider>
                  </QueryClientProvider>
                </ThemeProvider>
              </LogsProvider>
            </>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default withLDProvider({
  clientSideID: environment.launchDarkly.sdkKey,
  options: {
    logger: basicLogger({
      level: environment.name === "production" ? "error" : "info",
    }),
  },
})(App);
